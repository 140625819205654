import React from 'react'
import { graphql } from 'gatsby'
// import loadable from '@loadable/component'
//
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  cn
} from '../lib/helpers'
//
import { Container, Row, Column } from '../components/structure'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import { ResponsiveSlideshow } from '../components/gallery'
import CategoryCardList from '../components/category-card-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

// const CategoryCardList = loadable(() => import('../components/category-card-list'))

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    gallery: sanityGallery(slug: { current: { regex: "/homepage-gallery/" } }) {
      id
      title
      galleryImages {
        _key
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          metadata {
            lqip
            dimensions {
              aspectRatio
            }
          }
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
        alt
        caption
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const sliderGallery = (data || {}).gallery
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {sliderGallery && <ResponsiveSlideshow images={sliderGallery.galleryImages} />}
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        <Row cols={1}>
          <Column>
            <CategoryCardList />
          </Column>
        </Row>
        <Row cols={1}>
          <Column>
            {projectNodes && (
              <ProjectPreviewGrid
                title="Latest projects"
                browseMoreHref="/projects/"
                cols={[2, 4, 4]}
              />
            )}
          </Column>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
