import React from 'react'
// import { motion } from 'framer-motion'
import { useStaticQuery, graphql, Link } from 'gatsby'
import loadable from '@loadable/component'

//
import { cn } from '../lib/helpers'
import { Button } from './elements'
// split
const ProjectPreview = loadable(() => import('./project-preview'))
//
import styles from './project-preview-grid.module.css'

const gridItems = {
  hidden: {
    opacity: 0,
    scale: 0.6
  },
  visible: {
    opacity: 1,
    scale: 1
  },
  hover: {
    boxShadow: '0 0 8px rgba(0,0,0, 0.7)'
  }
}

function ProjectPreviewGrid(props) {
  const { cols, title, browseMoreHref } = props
  const data = useStaticQuery(graphql`
    query ProjectPreviewList {
      projects: allSanityProject(
        limit: 4
        sort: { fields: [publishedAt], order: DESC }
        filter: { publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            _key
            mainImage {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
                metadata {
                  lqip
                  dimensions {
                    aspectRatio
                  }
                }
                fluid(maxHeight: 460) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
            title
            description
            slug {
              current
            }
          }
        }
      }
    }
  `)
  return (
    <div className={cn(styles.root, `my-6`)}>
      {title && (
        <h2
          className={cn(
            `font-body text-2xl superstock:text-3xl supersport:text-4xl font-bold text-brand-mainalpha70 mb-5`
          )}
        >
          {title}
        </h2>
      )}
      <ul
        className={cn(
          `grid gap-4 grid-flow-row list-style-none`,
          cols &&
            `grid-cols-1 superstock:grid-cols-${cols[0]} supersport:grid-cols-${cols[1]} superbike:grid-cols-${cols[2]} }`
        )}
      >
        {data.projects.edges &&
          data.projects.edges.map(project => (
            <li key={project.node.id} className={`relative overflow-hidden`}>
              <ProjectPreview {...project.node} />
            </li>
          ))}
      </ul>
      {browseMoreHref && (
        <div className="flex w-full mt-5">
          <Button
            element={`link`}
            to={browseMoreHref}
            bg={`bg-brand-main`}
            classes={`w-auto text-center`}
          >
            Browse more projects
          </Button>
        </div>
      )}
    </div>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
  cols: [2, 4, 4]
}

export default ProjectPreviewGrid
