import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { motion, useViewportScroll } from 'framer-motion'
//
import { cn } from '../lib/helpers'
//
import styles from './category-card-list.module.css'
// import { grid } from './gallery/galleries-grid.module.css'
// import { responsiveTitle1, responsiveTitle2 } from './typography.module.css'
//

const catItem = {
  hidden: {
    opacity: 1,
    scale: 1
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      default: { duration: 0.3, delay: 0.1, ease: 'linear' },
      scale: { duration: 0.2, delay: 0 }
    }
  },
  hover: {
    scale: 1.1
  }
}

function CategoryCardList() {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const data = useStaticQuery(graphql`
    query CategoryList {
      categories: allSanityCategory(sort: { fields: [title], order: DESC }) {
        edges {
          node {
            id
            _id
            title
            slug {
              current
            }
            precis
          }
        }
      }
    }
  `)

  useEffect(() => {
    // const { scrollY, scrollYProgress } = useViewportScroll()
    // console.log('ScrollY', scrollY, 'Progress', scrollYProgress)
  })

  return (
    <ul
      className={cn(
        `grid gap-4 grid-flow-row list-style-none grid-cols-1 superstock:grid-cols-2 supersport:grid-cols-4 superbike:grid-cols-4`,
        styles.root
      )}
    >
      {data.categories.edges &&
        data.categories.edges.map(category => (
          <motion.li
            key={category.node._id}
            initial="hidden"
            animate={'visible'}
            variants={catItem}
            whileHover="hover"
            whileTap="hover"
          >
            <Link
              className={cn(styles.wrapperLink)}
              to={`/services/${category.node.slug.current}`}
              title={`More information on ${category.node.title}`}
            >
              <div className={cn(styles.cardContent)}>
                <h3
                  className={`font-display font-bold text-xl text-brand-main supersport:text-2xl`}
                >
                  {category.node.title}
                </h3>
                {category.node.precis && (
                  <p className={`font-body text-md text-gray-800 supersport:text-lg `}>
                    {category.node.precis}
                  </p>
                )}
                {/*<span>Photos & projects in {category.node.title}</span>*/}
              </div>
            </Link>
          </motion.li>
        ))}
    </ul>
  )
}

CategoryCardList.defaultProps = {
  crop: 'crop',
  format: 'webp',
  isLazy: true
}

export default CategoryCardList
